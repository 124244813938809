import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { utils } from '../utils/utils';

@Injectable()
export class ConfiguracionWebService {
    public headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8', 'Accept': 'application/json, text/javascript, /;' });

    constructor(private _http: Http) {
        this._utils = new utils();
    }
    _utils: utils;

    consultarParametro(idRazonSocial, codigo) {
        let obj = {
            idRazonSocial: idRazonSocial,
            codigo: codigo
        }
        let params = JSON.stringify(obj);
        return this._http.post(this._utils.url + "ConfiguracionWeb/consultarParametro", params, { headers: this.headers })
            .map(res => {
                return res.text();
            });
    }
}
