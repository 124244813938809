import { Injectable} from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { utils } from '../utils/utils';

@Injectable()
export class UbicacionContenedoresService {
    public headers = new Headers({'Content-Type': 'application/json; charset=utf-8', 'Accept': 'application/json, text/javascript, /;'});

    constructor(private _http: Http) {
        this._utils = new utils();
    }

    _utils: utils;

    // consultarUbicacionContenedores(id): Observable<any[]> {
    //     return this._http.get(`${this._utils.url}UbicacionContenedores/consultarUbicacionContenedores?idRazonSocial=`+id, {headers: this.headers}).pipe(
    //     map((res: any) => {
    //         return res.json();
    //     }));
    // }

    consultarUbicacionContenedores(id, idEmbarqueConvenio) {
        let param = {
          idRazonSocial: id,
          idEmbarqueConvenio:idEmbarqueConvenio
        }
        const json = JSON.stringify(param);
        return this._http
          .post(`${this._utils.url}UbicacionContenedores/consultarUbicacionContenedores/`, json, { headers: this.headers })
          .map((response) => {
            try {
              if (response.json() !== 'null') {
                return JSON.parse(response.text());
              } else {
                return null;
              }
            } catch (e) {
              return null;
            }
          });
      }

    modificarUbicacionContenedor(params: any): Observable<any[]> {
        let json = JSON.stringify(params);

        return this._http.post(`${this._utils.url}UbicacionContenedores/modificarUbicacionContenedor`, json, {headers: this.headers}).pipe(
        map((res: any) => {
            return res.json();
        }));
    }

    consultarUbicacionContenedoresHistorial(params: any): Observable<any[]> {
        let json = JSON.stringify(params);

        return this._http.post(`${this._utils.url}UbicacionContenedores/consultarUbicacionContenedoresHistorial`, json, {headers: this.headers}).pipe(
        map((res: any) => {
            return res.json();
        }));
    }

    consultarControladorContenedores(params: any): Observable<any[]> {
        let json = JSON.stringify(params);

        return this._http.post(`${this._utils.url}Contenedores/consultarControladorContenedores`, json, {headers: this.headers}).pipe(
        map((res: any) => {
            return res.json();
        }));
    }
}