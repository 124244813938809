import { Injectable, EventEmitter } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';

import { utils } from '../utils/utils';
import * as $ from 'jquery';
import { Parada } from '../models/parada';
import { Operador } from '../models/programas/operador';
@Injectable()
export class ParadaService {
  // public usuario:Array<any>;
  public headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8', 'Accept': 'application/json, text/javascript, /;' });
  //public headers = new Headers({'Content-Type': 'application/json; charset=utf-8'});


  constructor(private _http: Http, private _utils: utils) {
    this._utils = new utils();
  }

  /*consultarPais(idPais: number): Observable<Pais> {
    // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
   //var pais = new Pais(0,"");
   //pais.idPais = idPais;
   const json = JSON.stringify(pais);
      const params = json;
    return this._http
      .post(`${this._utils.url}Pais/consultarPais/`,params, {headers: this.headers} )
      .map((response) => {
        try {
          if (response.json() !== 'null') {
            return JSON.parse(response.json());
          } else {
            return null;
          }
        } catch (e) {
          return null;
        }
      });
  }*/

  /*consultarPaises(): Observable<Pais[]> {
      // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
      return this._http
        .get(`${this._utils.url}Pais/consultarPaises` )
        .map((response) => {
          try {
            if (response.json() !== 'null') {
              console.log(response.text());
            return JSON.parse(response.text());
            } else {
              return null;
            }
          } catch (e) {
            return null;
          }
        });
    }*/
  consultarParadas(idRuta: number) {
    var parada = {
      idRuta:idRuta
    }
    //var parada = new Parada(0, 0, "", 0, 0, 0, 0, 0, "", 0, 0, "", "");
    parada.idRuta = idRuta;
    const json = JSON.stringify(parada);
    return this._http
      .post(`${this._utils.url}Parada/consultarParadas/`, json, { headers: this.headers })
      .map((response) => {
        try {
          if (response.json() !== 'null') {
            return JSON.parse(response.text());
          } else {
            return null;
          }
        } catch (e) {
          return null;
        }
      });
  }

  consultarParadasSolicitud(idRuta) {
    return this._http.get(`${this._utils.url}Parada/consultarParadasSolicitud?idRuta=` + idRuta, { headers: this.headers })
    .map((response) => {
      try {
        if (response.json() !== 'null') {
          return JSON.parse(response.text());
        } else {
          return null;
        }
      } catch (e) {
        return null;
      }
    });
  }

  consultarOperadores(idRazonSocial): Observable<Operador[]> {

    let param = {
      idRazonSocial: idRazonSocial
    }
    const json = JSON.stringify(param);


    return this._http
      .post(`${this._utils.url}Operador/consultarOperadoresRuta/`, json, { headers: this.headers })
      .map((response) => {
        try {
          if (response.json() !== 'null') {
            return JSON.parse(response.text());
          } else {
            return null;
          }
        } catch (e) {
          return null;
        }
      });
  }


  agregarParadas(paradas: any) {
    // let params = new URLSearchParams();
    // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
    const json = JSON.stringify(paradas);
    const params = json;
    return this._http
      .post(`${this._utils.url}Parada/guardarParadaReal/`, params, { headers: this.headers })
      .map((response) => { return response.statusText });
  }

  actualizarParadas(paradas: any) {
    // let params = new URLSearchParams();
    // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
    const json = JSON.stringify(paradas);
    const params = json;
    return this._http
      .post(`${this._utils.url}Parada/actualizarParadasReales/`, params, { headers: this.headers })
      .map((response) => { return response.statusText });
  }

  /*actualizarPais(pais: Pais) {
      const json = JSON.stringify(pais);
      const params = json;
      return this._http
        .post(`${this._utils.url}Pais/actualizarPais/`, params, {headers: this.headers} )
        .map((response) => response.json() as string);
  }*/
}
