import { Injectable, EventEmitter } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';

import { utils } from '../utils/utils';
import * as $ from 'jquery';
import { Ruta } from '../models/ruta';

@Injectable()
export class EmbarqueService {
  // public usuario:Array<any>;
  public headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8', 'Accept': 'application/json, text/javascript, /;' });
  //public headers = new Headers({'Content-Type': 'application/json; charset=utf-8'});


  constructor(private _http: Http, private _utils: utils) {
    this._utils = new utils();
  }

  exportarPDF(listaParadas) {

    const json = JSON.stringify(listaParadas);

    return this._http
      .post(`${this._utils.url2}Ruta/exportarPDF/`, json, { headers: this.headers })
      .map((response) => {
        try {
          if (response.statusText !== 'null' && response.statusText == 'OK') {
            console.log(response.text());
            return response.text();
          } else {
            return null;
          }
        } catch (error) {
          console.log(error);
          return null;
        }
      });
  }


  consultarParadasVisitadas(idRuta): Observable<any[]> {
    let params = {
      idRuta: idRuta
    }
    // let params = new URLSearchParams();
    // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
    const json = JSON.stringify(params);

    return this._http
      .post(`${this._utils.url}Ruta/consultarRutaParadasVisitadas/`, json, { headers: this.headers })
      .map((response) => {
        try {
          if (response.json() !== 'null') {
            //console.log(response.text());
            return JSON.parse(response.text());
          } else {
            return null;
          }
        } catch (e) {
          return null;
        }
      });
  }

  consultarRutas(): Observable<Ruta[]> {
    // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
    return this._http
      .get(`${this._utils.url}Ruta/consultarRutas`)
      .map((response) => {
        try {
          if (response.json() !== 'null') {
            //console.log(response.text());
            return JSON.parse(response.text());
          } else {
            return null;
          }
        } catch (e) {
          return null;
        }
      });
  }

  consultarRutasModal(obj): Observable<Ruta[]> {
    // let params = new URLSearchParams();
    // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
    const params = JSON.stringify(obj);
    // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
    return this._http
      .post(`${this._utils.url}Ruta/consultarRutasModal/`, params, { headers: this.headers })
      .map((response) => {
        try {
          if (response.json() !== 'null') {
            //console.log(response.text());
            return JSON.parse(response.text());
          } else {
            return null;
          }
        } catch (e) {
          return null;
        }
      });
  }

  consultarRuta(idRuta): Observable<any> {
    let params = {
      idRuta: idRuta
    }
    const json = JSON.stringify(params);
    return this._http
      .post(`${this._utils.url}Ruta/consultarRuta/`, json, { headers: this.headers })
      .map((response) => {
        try {
          if (response.json() !== 'null') {
           // console.log(response.text());
            return JSON.parse(response.text());
          } else {
            return null;
          }
        } catch (e) {
          return null;
        }
      });
  }

  cancelarRuta(params): Observable<any> {

    const json = JSON.stringify(params);
    return this._http
      .post(`${this._utils.url}Ruta/cancelarRuta/`, json, { headers: this.headers })
      .map((response) => {
        try {
          if (response.json() !== 'null') {
            console.log(response.text());
            return JSON.parse(response.text());
          } else {
            return null;
          }
        } catch (e) {
          return null;
        }
      });
  }

  consultarEmbarques(fechaInicio: string, fechaFin: string, idRazonSocial: any): Observable<any[]> {
    return this._http
      .get(`${this._utils.url}Embarque/consultarEmbarques?fechaInicio=` + fechaInicio + `&fechaFin=` + fechaFin + `&idRazonSocial=` + idRazonSocial)
      .map((response) => {
        try {
          if (response.json() !== null) {
            return response.json();
          } else {
            return null;
          }
        } catch (e) {
          return null;
        }
      });
  }

  eliminarEmbarque(parametros) {
    const json = JSON.stringify(parametros);
    const params = json;
    return this._http
      .post(`${this._utils.url}Embarque/eliminarEmbarque/`, params, {headers: this.headers} )
      .map((response) => {
        try {
          if(response.statusText !== 'null' && response.statusText == 'OK'){
           // console.log(response.text());
            return response.statusText;
          }else{
            return null;
          }
        } catch (error) {
          console.log(error);
          return null; 
        } 
      });
    }

  consultarEmbarqueConvenios(idRazonSocial)  {
  var parametros = {
    idRazonSocial:idRazonSocial  
  }
  const json = JSON.stringify(parametros);
  const params = json;
  return this._http
    .post(`${this._utils.url}Embarque/consultarEmbarqueConvenios` ,params, {headers: this.headers})
    .map((response) => {
      try {
        //console.log(response.json());
        if (response.json() !== 'null') {
        //console.log(response.text());
        return JSON.parse(response.text());
        } else {
          return null;
        }
      } catch (e) {
      return null;
      }
    });
  }
}
